.single-match-card .block-competition__head {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 10px 10px 0 0;
}

.single-match-card .match {
  padding: 30px 70px;
  background: #f3f3f3;
  border-radius: 0;
  border: none;
}

.single-match-card a.match {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIzMyIgdmlld0JveD0iMCAwIDE4IDMzIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTIgMzAuNjAyMUwxNiAxNi42MDIxTDIgMi42MDIwNSIgc3Ryb2tlPSIjMTMzNTdBIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4=')
      no-repeat center right 70px,
    #f3f3f3;
  background-size: 15px;
  padding-right: 100px;
}

.single-match-card .match .match__data {
  flex-grow: 1;
}

.single-match-card .match .match__data .match__club {
  padding-inline: 0;
  display: flex;
  align-items: center;
  width: auto;
}

.single-match-card .match .match__data .match__club img {
  /* width: 40px; */
  height: 40px;
}

.single-match-card .match .match__data .match__club p:first-child {
  text-transform: uppercase;
  font-weight: 700;
  color: #13357a;
  font-size: 28px;
  position: relative;
  line-height: 1;
}

.single-match-card .match .match__data .match__club p:first-child img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.single-match-card .match .match__data .match__club .match__score {
  font-weight: 700;
  color: #b60718;
  font-size: 32px;
}

.single-match-card .match .match__data .match__club .match__score.null span {
  display: none;
}

.single-match-card .match .match__data .match__club:first-child {
  justify-content: flex-end;
}

.single-match-card .match .match__data .match__club:first-child p:first-child {
  padding-right: 60px;
}

.single-match-card .match .match__data .match__club:first-child p:first-child img {
  right: 0;
}

.single-match-card .match .match__data .match__club:first-child .match__score {
  width:2em;
}

.single-match-card .match .match__data .match__club:last-child {
  flex-direction: row-reverse;
  justify-content: start;
}

.single-match-card .match .match__data .match__club:last-child p:first-child {
  padding-left: 60px;
}

.single-match-card .match .match__data .match__club:last-child p:first-child img {
  left: 0;
  height: auto;
}

@media (min-width: 768px) {
  .single-match-card .match .match__data .match__club:last-child p:first-child img {
    width: auto;
    max-width: 40px;
    max-height: 40px;
  }
}

.single-match-card .match .match__data .match__club:last-child .match__score {
  width:2em;
}

.single-match-card .match .match__data .match__info {
  width: auto;
}

.single-match-card .match .match__data .match__info div span,
.single-match-card .match .match__data .match__info .match__score .match__colon {
  font-size: 32px;
  color: #b60718;
  font-weight: 700;
}

.single-match-card .match .match__data .match__info div {
  margin-inline: 45px;
}

.single-match-card .match .match__data .match__info .match__score .match__colon {
  margin-inline: 15px;
}

.single-match-card .block-competition__head {
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background: #002a61;
}

.single-match-card.is-live .block-competition__head {
  background: var(--red-dark, #b60718);
}

.single-match-card.is-live .block-competition__head img {
  display: inline-block;
  margin-left: 4px;
  height: 15px;
  width: auto;
  vertical-align: middle;
}

.single-match-card.is-over .block-competition__head {
  background: #d6dce2;
  color: #13357a;
}

.single-match-card .match.box {
  position: relative;
  height: auto;
}

.single-match-card .match__data {
  display: grid;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 4px;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto 1fr;
}

.single-match-card .match__club img {
  position: unset;
  top: unset;
  margin-top: unset;
  margin: 0;
  /* border-radius: 50%; */
}

.single-match-card .ticket-link {
  padding: 13px 68px;
  border-radius: 35px;
  background-color: rgba(182, 7, 24, 1);
  text-transform: uppercase;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}

.single-match-card .ticket-link:hover {
  background-color: rgba(182, 7, 24, 0.8);
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .single-match-card .match {
    flex-direction: column;
  }

  .single-match-card .ticket-link {
    margin-top: 30px;
  }

  .single-match-card .match {
    padding: 30px;
  }

  .single-match-card a.match {
    background-position: center right 30px;
    padding-right: 60px;
  }
}

@media screen and (max-width: 768px) {
  .single-match-card a.match {
    background: #f3f3f3;
    padding-right: 30px;
  }

  .single-match-card .match .match__data {
    width: 100%;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .single-match-card .match .match__data .match__club {
    width: 100%;
    justify-content: space-between !important;
  }

  .single-match-card .match .match__data .match__club img {
    width: 20px;
    height: 20px;
  }

  .single-match-card .match .match__data .match__club p:first-child {
    font-size: 16px;
  }

  .single-match-card .match .match__data .match__club:last-child {
    flex-direction: row;
    margin-top: 15px;
  }

  .single-match-card .match .match__data .match__club:first-child p:first-child {
    padding-right: 0;
    padding-left: 30px;
  }

  .single-match-card .match .match__data .match__club:first-child p:first-child img {
    left: 0;
    right: auto;
    height: auto;
  }

  .single-match-card .match .match__data .match__club:last-child p:first-child {
    padding-left: 30px;
  }

  .single-match-card .match .match__data .match__club .match__score:not(.null) {
    font-size: 16px;
    width:auto;
  }

  .single-match-card .match .match__data .match__club .match__score.null {
    display: block;
    font-weight: 400;
    width:auto;
  }

  .single-match-card .match .match__data .match__club .match__score.null span{
    display:block;
  }

  .single-match-card .match .match__data .match__club:last-child .match__score {
    margin-right: 0;
    margin-left: 45px;
    width:auto;
  }

  .single-match-card .match .match__data .match__info {
    display: none;
  }
}
