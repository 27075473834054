@media (min-width: 768px) { 
  .cookie {
    padding-right: 10px;
    display: flex; }
    .cookie p {
      margin: auto; }
      .cookie .btn-container {
        margin-left: 80px;}
        .cookie .btn {
          position: relative;
          margin: 0.5rem;
          top: 0;
          right: 0;
          width: 200px;
          padding: 0 32px; } }
