.gallery-wrapper{
  display: flex;
  overflowX: scroll;
}

.gallery-big-img{
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-big-img figure{
  height: 100%;
}

.gallery-big-img img{
  height: 100%;
  width: auto;
}
