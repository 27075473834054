@import '@material-design-icons/font';

:root {
  --gutter: 16px;
}

.text-center {
  text-align: center;
}
.has-sidebar {
  & > .row {
    margin-left: 0;
    & > .col {
      padding-left: 0;
    }
  }
}
.card--square {
  aspect-ratio: 1/1;
  height: auto;
}

.fid_inscription {
  height: auto !important;
  display: grid !important;
  grid-template-columns: 0.7fr 2.3fr !important;
  .card__body {
    position: relative;
    height: auto;
    width: auto;
  }
  .logo {
    background: url(../../assets/img/fid/fid_logo_couleur_back_left.png) no-repeat top left;
    background-size: contain;
    text-align: center;
    display: grid;
    img {
      margin: 1.5em auto;
      grid-column: 1;
      grid-row: 1 / -1;
      align-self: center;
      max-width: 80%;
    }
  }
  .card__body {
    color: #fff;
    background: url(../../assets/img/fid/fid_back_coq_coloured.png) no-repeat,
      linear-gradient(83.77deg, #164192 -4.24%, #a51a59 48.83%, #e21f20 101.9%);
    background-size: cover;
    padding: 0 !important;
    & > div {
      padding: 24px 48px !important;
      padding-left: 18% !important;
      background: url(../../assets/img/fid/fid_fleche.svg);
      background-position: top left;
      background-repeat: no-repeat;
      background-size: contain;
    }
    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
    p {
      color: #fff !important;
      font-size: 20px !important;
    }
    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3em;
      .btn--white {
        color: #2a9bff !important;
      }
      .btn {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .avantages {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr !important;
      gap: 1em;
      text-align: center;
    }
  }
}
.fid_banner {
  background-color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr;
  .logo {
    background: url(../../assets/img/fid/fid_logo_couleur_back.png) no-repeat bottom center;
    background-size: cover;
  }
  img {
    margin: 1.5em auto;
    display: block;
  }
  .points {
    //background: url(../../assets/img/fid/fid_points_back.png) no-repeat bottom left;
    background: #1e2b6b url(../../assets/img/fid/fid_points_back_bleu.png) no-repeat bottom left;
    background-size: contain;
    margin-left: -3vw;
    position: relative;
    div {
      font-size: 42px;
      text-align: center;
      line-height: 1;
      margin-top: -20px;
      color: #fff;
      font-weight: bold;
    }
    &.small div {
      font-size: 36px;
    }
    p {
      margin: 0;
      line-height: normal;
      &.small {
        font-size: 16px;
        font-weight: normal;
      }
    }
    & > p {
      display: none;
      position: absolute;
      bottom: 10px;
      width: calc(100% - 100px);
      text-align: center;
      color: #fff;
      overflow: auto;
      margin-left: 100px;
      a:hover {
        color: #fff;
      }
    }

    div,
    img {
      position: absolute;
      top: 50%;
      left: calc(50% + 30px);
      transform: translate(-50%, -50%);
      margin: 0;
      margin-top: -10px;
    }
  }
}

.card--fidelite {
  padding: 32px 48px;
  &.card--faq {
    padding: 20px;
  }
  font-size: 18px;
  height: auto !important;
  .section__link {
    color: #000 !important;
    font-weight: 500 !important;
    i {
      color: #7d93a8 !important;
      margin-left: 8px !important;
    }
  }
  .box--header {
    display: grid;
    grid-template-columns: 65% 35%;
    padding: 16px 0;
    .bouton {
      text-align: right;
    }
  }
  .accordion-trigger.is-selected {
    margin: 0 -20px 0 -20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: calc(100% + 40px);
    box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08) !important;
  }

  .accordion-panel {
    margin: 0 -20px 0 -20px;
    .content {
      padding: 20px;
      background: linear-gradient(180deg, rgba(41, 41, 41, 0.14) 0%, rgba(255, 255, 255, 0) 61.64%), #ffffff;
    }
  }
}

.card--fid__large {
  position: relative;
  margin: 0 -16px;
  &:hover {
    text-decoration: none;
  }
  .card__body {
    display: flex;
    padding: 16px;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    .material-icons {
      vertical-align: text-bottom;
    }
    & > h3 {
      font-size: 24px;
    }
    & > p,
    & > h3 {
      padding: 0;
      margin: 0;
      line-height: 48px;
      padding-right: 16px;
      display: inline-block;
    }
    & > button {
      margin: 0;
      margin-left: auto;
      display: inline-block;
      white-space: nowrap;
    }
  }
}
.card__fid {
  position: relative;
  border-radius: 8px;
  margin-top: 32px;
  aspect-ratio: 236/372;
  width: 350px;
  height: auto;
  .card__color {
    position: absolute !important;
    z-index: 10;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 38.6%);
    transform: rotate(-180deg);
    height: 100%;
    width: 100%;
  }
  .card__body {
    position: absolute !important;
    z-index: 11;
    bottom: 0;
    text-align: center;
    padding: 16px;
    h3 {
      font-size: 18px;
      line-height: 22px;
    }
    &.card__body__center {
      position: absolute !important;
      z-index: 11;
      bottom: auto;
      text-align: center;
      padding: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.card__points {
  position: absolute;
  top: 10px;
  left: 50%;
  font-size: 22px;
  transform: translateX(-50%);
  color: #999;
  text-align: center;
  background: url(../../assets/img/fid/fid_ecusson_back_points.png) no-repeat bottom center;
  min-width: 110px;
  min-height: 119px;
  font-family: 'Din';
  line-height: 1;
  padding-top: 20px;
  span {
    display: block;
  }
  .nb_points {
    font-size: 38px;
    font-weight: bold;
    color: #d40000;
  }
}

.ma-fidelite-offre {
  padding: 0 !important;
  & > a {
    display: block;
    padding: 0 48px;
  }
}
.fidelite--offre {
  border-bottom: none;
  .type_offre {
    display: inline-block;
    background-color: #000;
    margin: 8px 0 24px 0;
    padding: 8px 16px;
    border-radius: 24px;
    color: #fff;
    line-height: 24px;
    font-size: 16px;
    .material-icons {
      vertical-align: middle;
    }
    a:hover {
      color: #fff;
    }
  }
  .header_offre {
    display: grid;
    grid-template-columns: 2fr 1fr;
    div {
      position: relative;
    }
  }
}
.bientot_dispo {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  background-color: #1e2b6b;
  z-index: 15;
  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 16px;
  }
}

.onglets-fidelite {
  width: 100%;
  ol {
    text-align: center;
    list-style: none;
    white-space: nowrap;
    overflow-x: scroll;
    &.filtres {
      text-align: left;
      li:first-child {
        margin-left: 0;
      }
    }
    li {
      display: inline-block;
      margin: 0 1em;
      a,
      button,
      span {
        padding: 5px;
        display: block;
        border-bottom: 3px solid transparent;
      }
      a,
      button {
        &.selected {
          font-weight: bold;
        }
        &.selected,
        &:hover {
          text-decoration: none;
          border-image: linear-gradient(83.77deg, #164192 -4.24%, #a51a59 48.83%, #e21f20 101.9%);
          border-image-slice: 1;
          color: inherit;
        }
      }
    }
  }
}

header.type--offers {
  background-color: #000;
  color: #fff;
  padding: 16px;
  display: grid;
  grid-template-columns: 70px auto 10%;
  gap: 16px;
  .link-chevron {
    border: 2px solid #fff;
    border-radius: 4px;
    text-align: center;
    height: 64px;
    width: 64px;
    i {
      float: none;
      margin: 0;
      color: #fff;
      line-height: 64px;
      font-size: 16px;
    }
  }
  .offer--icon {
    text-align: right;
    .material-icons {
      font-size: 48px;
      margin: 10px 0;
    }
  }
}

.card--fidelite-offre {
  .text--conditions {
    margin-top: 32px;
    color: #999999;
  }
}

.liste-avantages {
  display: grid;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  width: 100%;
  gap: 16px;
  overflow-x: scroll;
  & > * {
    grid-row: 1;
    grid-column: auto;
  }
  h3 {
    white-space: normal;
  }
}

.liste-offres {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  .card__fid {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .card__fid {
    .card__body {
      h3 {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
  .card--fidelite-presentation {
    display: grid !important;
    grid-template-columns: 50% 50%;
  }

  .card--fidelite-offre {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 32px 48px;
    grid-gap: 48px;
  }
  .liste-offres {
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1080px) {
  .fid_banner {
    .points {
      & > p {
        display: block;
      }
    }
  }
  .onglets-fidelite {
    justify-content: center;
  }
  .fid_banner {
    margin: 0 -16px 3em 0;
  }

  .liste-offres {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    .card__fid {
      width: 100%;
    }
  }
}

@media (min-width: 1800 px) {
  .liste-offres {
    grid-template-columns: repeat(4, 1fr);
  }
}
/** Modales **/
.fid-modal {
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .fid-modal {
    width: 750px;
  }
}

.modal .fid-modal .icon-close {
  color: #fff;
}

.fid-modal.fid-modal--blue-background {
  background: url(../../assets/img/fid/fid_modal_blue_vector.png) no-repeat bottom right;
  background-color: #1e2b6b;
}

.fid-modal.fid-modal--blue-background .modal__body {
  color: #fff;
}

.fid-modal.fid-modal--blue-background h3 {
  font-family: SummerLovingSolid;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fid-modal.fid-modal--blue-background p {
  /* Title/H1 */
  font-family: DIN;
  font-size: 24px;
  line-height: normal;
  text-align: center;
}
