.dashboard-links {
  overflow: hidden;
}

.hide-dashboard-links .dashboard-links {
  max-height: 0;
}

.hide-dashboard-links .dashboard-link-header,
.hide-dashboard-links.dashboard-menu__settings p:first-child {
  margin-bottom: 0;
}

.dashboard-link-header {
  cursor: pointer;
  user-select: none;
}

.dashboard-link-header .icon {
  font-size: 0.4em;
  color: #004c99;
  float: right;
  padding-top: calc(10px - 0.4em);
}

.dashboard-links .dashboard-link-right-icon {
  margin-left: 1em;
}

.dashboard-links .small-icon {
  font-size: 0.5em;
  padding-bottom: 0.25em;
}

.icon-red {
  color: red;
}

.icon-for-you {
  padding-right: 32px;
}
