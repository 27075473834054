.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
}

body .marker-cluster-large div, body .marker-cluster-large, body .marker-cluster-medium div, body .marker-cluster-medium {
  background-color: rgba(0, 35,72, 0.6);
  color:#fff;
  font-weight:bold;
}

body .marker-cluster-small div, body .marker-cluster-small {
  background-color: rgba(42, 155, 255, 0.6);
  color:#fff;
}

.leaflet-container .card--clubSmall .card__body {
  width:auto;
}

.card--club .card__body {
  min-height:132px;
}

body .carte--centenaire .leaflet-popup-content {
  min-width:300px;
  margin:0;
}

body .carte--centenaire .leaflet-popup-content .card--centenaire {
  border-radius:12px;
}

body .carte--centenaire .leaflet-popup-content-wrapper{
  padding:0;
}
body .carte--centenaire .marker-cluster-large div, body .carte--centenaire .marker-cluster-large, body .carte--centenaire .marker-cluster-medium div, body .carte--centenaire .marker-cluster-medium {
  background-color: rgba(232, 168, 1, 0.6);
}

body .carte--centenaire .marker-cluster-small div, body .carte--centenaire .marker-cluster-small {
  background-color: rgba(232, 168, 1, 0.6);
}