.container__competitions-internationales .box.card.card-player--compact .card__thumbnail {
  border-radius: 4px;
}

.container__competitions-internationales .box.card.card-player--compact .card__title {
  color: var(--greyscale-true-white, #fff);
  font-family: 'Din';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 141%; /* 33.84px */
}

.container__competitions-internationales .box.card.card-player--compact .card__desc.ft-h6.ft-up {
  color: var(--red-dark, #b60718);
  font-family: 'Din';
  font-size: 12px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 141%; /* 16.92px */
}

/* @media (min-width: 768px) {
  .container__competitions-internationales .box.card.card-player--compact .card__title {
    font-size: 24px;
  }
} */

.card-player--compact.is-competition-players {
  overflow: unset;
}

.card-player--compact.is-competition-players .card__header {
  position: absolute;
  top: -40px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  color: #002a61;
}

/* Competition players  */
.competition-players {
  margin-bottom: 50px;
  padding: 0 30px;
}

@media (min-width: 1280px) {
  .competition-players {
    margin-bottom: 100px;
    padding: unset;
  }
}

.card-player--compact.is-competition-players .card__header p {
  text-align: center;
}

.card-player--compact.is-competition-players .card__thumbnail {
  height: 408px;
}

.card-player--compact.is-competition-players .card__body {
  bottom: -100px;
  text-align: center;
}

.card-player--compact.is-competition-players .card__title {
  color: #002a61;
}

.card-player--compact.is-competition-players .card__desc {
  color: #b60718;
}

/* Conteneur bleu  */
.card-player--compact.is-conteneur-bleu {
  margin-top: 32px;
  padding: 0 10px 0 20px;
  min-width: 280px;
}

@media (min-width: 768px) {
  .card-player--compact.is-conteneur-bleu {
    padding: unset;
    padding-left: unset;
  }
}

.card-player--compact.is-conteneur-bleu .card__thumbnail {
  height: 408px;
}

.card-player--compact.is-conteneur-bleu .card__body {
  text-align: center;
}

.card-player--compact.is-conteneur-bleu .card__body .card__title,
.card-player--compact.is-conteneur-bleu .card__body .card__desc {
  color: #fff;
}

@media (max-width: 1279px) {
  .card-player--compact.is-competition-players {
    height: fit-content;
    margin-bottom: 22px;
  }

  .card-player--compact.is-competition-players .card__header--mobile {
    color: #002a61;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
    text-transform: uppercase;
  }

  .card-player--compact.is-competition-players .card__content--mobile {
    display: flex;
  }

  .card-player--compact.is-competition-players .card__thumbnail--mobile {
    margin-right: 22px;
    height: 100px;
    width: 100px;
    background-position: top;
    background-size: cover;
  }

  .card-player--compact.is-competition-players .card__body--mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
