.header {
  .header__wrapper.blue-background {
    background-color: #002348;

    .menu {
      background-color: #002348;
    }

    .menu__link > li > a {
      color: #fff;
    }
  }
}

.ffr-tv__theme-selector {
  .home-slider__news-categories {
    position: relative;
    border-top: unset;

    li {
      padding: 0 10px;
    }
  }
}
