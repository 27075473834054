@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 1000;
  overflow: hidden
}

.modalTop{
  height: 10%;
}

.modalTop > icon{
  display: block;
}

.modalFooter{
  height: 20%;
  display: flex;
  align-items: center;
}

.modalFooter > div{
  margin: auto;
  width: auto;
}

.modalContent {
  width: 100%;
  position: relative;
  height: 100%;
  padding-left:0;
}

.modalWrapper {
  height: 70%;
}

.modalContent > div {
  top: 0;
  bottom: 0;
  margin: auto;
  width: 50%;
  position: absolute;
  max-height: 100%;
}

.modalContent > div > img {
  max-height:100%;
  max-width:100%;
  margin:0 auto;
  display:block;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
}

.centeredImage{
  left: 25%;
}

.prevImage{
  opacity: 0.3;
  right: 80%;
}

.nextImage{
  opacity: 0.3;
  left: 80%;
}

.closeButton {
  width: 50px;
  height: 50px;
}

.rightButtonContainer {
  position: absolute;
  right: 5%;
  top: 50%;
  background: white;
}

.leftButtonContainer {
  position: absolute;
  left: 5%;
  top: 50%;
  background: white;
  z-index: 2000;
}

@media (min-width: 1280px) {
.carousel-photo .news-slider__wrapper {
  justify-content: center;
}
}

.carousel-photo .news-slider__wrapper--picture {
  width: auto;
  white-space: nowrap;
  display:block;
}

.carousel-photo .news-slider .picture {
  display:inline-block;
}