.youtube-plylist--carousel {
    max-width: 1272px;
    padding: 100px 30px 50px 30px;
    width: 100%;
    overflow: visible;
}

.youtube-plylist--carousel .swiper-slide {
    width: auto;
    height: 530px;
    aspect-ratio: 16/10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.youtube-plylist--carousel .swiper-slide a {
    display: block;
    height: 300px;
    width: auto;
    aspect-ratio: 16/10;
    transition: height 250ms ease-in-out;
    border-radius: 14px;
    overflow: hidden;
}

.youtube-plylist--carousel .swiper-slide.swiper-slide-active a {
    height: 530px;
}

.youtube-plylist--carousel .swiper-slide.swiper-slide-prev {
    justify-content: end;
}

.youtube-plylist--carousel .swiper-slide.swiper-slide-next {
    justify-content: start;
}

.youtube-plylist--carousel .swiper-slide:not(.swiper-slide-visible) {
    opacity: 0;
}

.youtube-plylist--carousel .swiper-button-prev,
.youtube-plylist--carousel .swiper-button-next {
    position: absolute;
    top: 50px;
    right: 30px;
    width: 48px;
    height: 48px;
    opacity: .4;
    transition: opacity 250ms ease-in-out;
}

.youtube-plylist--carousel .swiper-button-prev:hover,
.youtube-plylist--carousel .swiper-button-next:hover {
    opacity: 1;
}

.youtube-plylist--carousel .swiper-button-next {
    right: 30px;
}

.youtube-plylist--carousel .swiper-button-prev {
    right: 90px;
    left: auto;
}

.youtube-plylist--carousel .swiper-button-prev:after,
.youtube-plylist--carousel .swiper-button-next:after {
    width: 48px;
    height: 48px;
}

.youtube-plylist--carousel .swiper-button-prev:after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDkiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OSA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMjMiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDQ4LjQyMTkgLTAuMDA3ODEyNSkiIHN0cm9rZT0iI0ZBRkFGQSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0yNy40MjE5IDI5Ljk5MjJMMjEuNDIxOSAyMy45OTIyTDI3LjQyMTkgMTcuOTkyMiIgc3Ryb2tlPSIjRkFGQUZBIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

.youtube-plylist--carousel .swiper-button-next:after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDkiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OSA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjQuNDIxOSIgY3k9IjIzLjk5MjIiIHI9IjIzIiBzdHJva2U9IiNGQUZBRkEiIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNMjEuNDIxOSAyOS45OTIyTDI3LjQyMTkgMjMuOTkyMkwyMS40MjE5IDE3Ljk5MjIiIHN0cm9rZT0iI0ZBRkFGQSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}

@media screen and (max-width: 1024px) {
    .youtube-plylist--carousel .swiper-slide {
        height: 400px;
    }

    .youtube-plylist--carousel .swiper-slide a {
        height: 205px;
    }

    .youtube-plylist--carousel .swiper-slide.swiper-slide-active a {
        height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .youtube-plylist--carousel {
        max-width: 1272px;
        padding: 30px 30px 0 30px;
    }

    .youtube-plylist--carousel .swiper-slide {
        height: 205px;
    }

    .youtube-plylist--carousel .swiper-slide a {
        height: 205px;
        border-radius: 6px;
    }

    .youtube-plylist--carousel .swiper-slide.swiper-slide-active a {
        height: 205px;
    }

    .youtube-plylist--carousel .swiper-button-prev,
    .youtube-plylist--carousel .swiper-button-next {
        display: none;
    }
}
