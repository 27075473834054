.faciliti-button {
  position: relative;
}

.faciliti-button.is-footer {
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 767px) {
  .faciliti-button.is-footer {
    padding: 0!important;
  }
}

.faciliti-button.is-aside {
  position: fixed;
  bottom: 20vh;
  min-width: 160px;
  width: 160px;
  min-height: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  right: -129px;
  -webkit-transition: right 0.2s ease;
  transition: right 0.2s ease;
  background-color: #013974;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: none;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 10000;
  cursor: pointer;
}

.faciliti-button.is-aside.is-open {
  right: 0;
}

.faciliti-button img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.faciliti-button.is-aside img {
  display: block;
  height: 60px;
  width: 60px;
  margin-left: -40px;
  background-color: transparent !important;
}

.faciliti-button.is-aside:hover {
  right: 0;
  width: 160px;
}

.faciliti-button.is-aside .cta-text {
  display: block;
  text-align: center;
  width: 120px;
}
@media (min-width: 768px) {
  .faciliti-button.is-aside {
    min-height: 75px;
    bottom: 32vh;
    min-width: 180px;
    width: 180px;
    /* -webkit-box-orient: horizontal; */
    /* -webkit-box-direction: reverse; */
    /* -ms-flex-direction: row-reverse; */
    /* flex-direction: row-reverse;
    left: -145px; */
    -webkit-transition: left 0.2s ease;
    transition: left 0.2s ease;
    right: -133px;
  }
  .faciliti-button.is-aside:hover {
    right: 0;
    width: 180px;
  }
  .faciliti-button.is-aside img {
    height: 75px;
    width: 75px;
    margin-right: -50px;
  }
}

.faciliti-button.is-aside {
  margin: auto;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
  font-size: 1.1em;
  line-height: 1.5;
  text-align: center;
}
