.Countdown{
    margin: 10px auto;
    padding-bottom: 20px;
    text-align:center;
    color:#fff;
  }
  
  .Countdown-col{
    display: inline-block;
  }
  
  .Countdown-col-element{
    background-color:#002348;
    padding:10px 15px;
    display: inline-block;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    font-weight:bold;
  }
  
  .Countdown-col-element strong{
    font-size: 50px;
    color:#F2362F;
  }
  .Countdown-message {
      text-align:center;
      padding:10px 20px;
      font-size: 1.2em;
      color:#F2362F;
      font-weight:bold;
  }
  
  @media (max-width: 900px) {
    .Countdown-col-element{
        margin: 0 5px;
    }
    .Countdown-col-element strong {
        font-size:30px;
    }

  }