.input--loc > a {
    display:block;
    padding-left: 56px;
    padding-right: 24px;
    line-height: 62px;
    background: no-repeat  url(../img/icon-delete-black.svg) 98% 24px;
}

.input--loc ul {
    opacity: 1;
    max-height: 80vh;
    overflow: auto;
    padding: 16px;
    box-shadow: 0 10px 16px rgb(51 51 51 / 8%), 0 0 0 1px #ebebeb;
    background:#fff;
    transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s;
}
.input--loc ul a {
    display: block;
    padding:3px 0;
}
.input--loc input {
    padding-left: 56px;
}
.input--loc:before{
    content: "\e917";
    font-family: "icon";
    font-size: 16px;
    line-height: 16px;
    color: #ccc;
    position: absolute;
    left: 16px;
    top: 32px;
    margin-top: -8px;
}

@media screen and (max-device-width: 480px) {
    .result-club__maps {
        padding-top:236px!important;
    }

    .result-club__maps .btn {
        top:245px!important;
    }
    .filter.filter--clubs {
        height: auto;
    }

    .filter.filter--clubs .filter__wrapper {
        display: block;
    }

    .input--loc input, .input--loc > a {
        padding-left: 40px;
    }

    .input--loc:before{
        top:28px;
    }
}
